
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      areacode: "",
      number_list: [""],
      per_hour_limit: "",
    });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const numbers = ref<any>(false);
    const profile = ref<any>(false);
    const submitButtonGetNumber = ref<HTMLButtonElement | null>(null);
    const submitButtonBuyNumber = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");
    const formAreaCode = ref("");
    const selected = ref([]);
    const registration = Yup.object().shape({
      areacode: Yup.number()
        .typeError("Please specify a number")
        .required()
        .label("Area Code"),
      // selectNumber: Yup.object({"selectNumber[]" }).required().label("Please Select One number"),
      per_hour_limit: Yup.number()
        .integer()
        .required()
        .typeError("Please Spacify a number")
        .label("Per Hour Limit"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Add Number", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
      // console.log('selected',selected.value);
    });
    const getUser = (id) => {
      console.log("test");
    };

    const getNumber = () => {
      submitButtonGetNumber.value!.disabled = true;
      submitButtonGetNumber.value?.setAttribute("data-kt-indicator", "on");
      var request = {
        url: "numbers",
        data: {
          areacode: formAreaCode.value,
        },
      };
      store
        .dispatch(Actions.POST, request)
        .then((data) => {
          if (data) {
            numbers.value = data.data;
            if (data.data.length > 0) {
              Swal.fire("Success", "Number Get successfully!", "success");
            } else {
              Swal.fire({
                text: "Local Numbers Are not Available For This Area Code!",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
            // console.log('dgfdfgfd',data.data);
          }
          submitButtonGetNumber.value?.removeAttribute("data-kt-indicator");
          submitButtonGetNumber.value!.disabled = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          submitButtonGetNumber.value?.removeAttribute("data-kt-indicator");
          submitButtonGetNumber.value!.disabled = false;
        });
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors
      if (values.number_list) {
        if (values.number_list.length == 0) {
          Swal.fire({
            text: "Please Select Number!",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else {
          submitButtonBuyNumber.value!.disabled = true;
          submitButtonBuyNumber.value?.setAttribute("data-kt-indicator", "on");
          console.log("values", values);

          //ajax of form
          var request = {
            url: "number-buy",
            data: {
              areacode: values.areacode,
              number_list: values.number_list,
              per_hour_limit: values.per_hour_limit,
            },
          };
          store
            .dispatch(Actions.POST, request)
            .then((data) => {
              if (data) {
                Swal.fire("Success", "Number buy successfully!", "success");
                router.push({ name: "user-number" });
              }
              loading.value = false;
            })
            .catch(() => {
              const [error] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors[error],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
          submitButtonBuyNumber.value?.removeAttribute("data-kt-indicator");
          submitButtonBuyNumber.value!.disabled = false;
        }
      } else {
        Swal.fire({
          text: "Please select the number!",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Try Again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        }).then(function () {
          // router.push({ name: "user-number-create" });
        });
      }
      // }, 2000);
      // Swal.fire({
      //   text: "Coupon added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   // router.push({ name: "user-number-create" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButtonGetNumber,
      submitButtonBuyNumber,
      loading,
      page,
      formAreaCode,
      numbers,
      getNumber,
      formData,
      selected,
    };
  },
});
